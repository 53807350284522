import React from 'react';
import { Link } from 'react-router-dom';

function HomePage() {
  return (
    <div>
      <h1>トップページ</h1>
      <Link to="/estates">不動産選びメモアプリへ</Link>
    </div>
  );
}

export default HomePage;
