import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { getAllEstates } from '../utils/supabaseFunctions';

// 不動産情報の型定義
type Estate = {
  id: number;
  name: string;
  price: number; // 家賃
  subPrice: number; // 共益費
  yearBuilt: number; // 築年月
  planOfAHouse?: string; // 間取り
  exclusiveArea?: number; // 専有面積
  address?: string; // 住所
  memo?: string; // メモ
  url?: string; // URL
  map_url?: string; // 地図URL
};

const Estates = () => {

  const [estates, setEstates] = useState<Estate[]>([]);

  useEffect(() => {
    const getEstates = async () => {
      const fetchedEstates = await getAllEstates();
      setEstates(fetchedEstates || []); // fetchedEstatesがnullの場合は空の配列を設定
    };
    getEstates();
  }, []);

  const [sortConfig, setSortConfig] = React.useState({ key: 'id', direction: 'ASC' });

  const handleSort = (key: keyof Estate) => {
    let direction = 'ASC';
    if (sortConfig.key === key && sortConfig.direction === 'ASC') {
      direction = 'DESC';
    }
    const sortedEstates = [...estates].sort((a, b) => {
      const aValue = a[key] ?? 0;
      const bValue = b[key] ?? 0;
    
      if (aValue < bValue) {
        return direction === 'ASC' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'ASC' ? 1 : -1;
      }
      return 0;
    });
    setEstates(sortedEstates);
    setSortConfig({ key, direction });
  };

  const getSortDirection = (key: string) => {
    return sortConfig.key === key ? (sortConfig.direction === 'ASC' ? '▲' : '▼') : ' 　';
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>不動産選びメモ用</h1>
      </header>
      <div className='flex overflow-x-auto mx-5'>
      <TableContainer component={Paper} className='mt-5 flex-none'>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className='bg-gray-200 color'>
              <TableRow className='cursor-pointer'>
                <TableCell style={{ minWidth: '70px' }} onClick={() => handleSort('id')}>ID{getSortDirection('id')}</TableCell>
                <TableCell style={{ minWidth: '150px' }} onClick={() => handleSort('name')} align="left">物件名{getSortDirection('name')}</TableCell>
                <TableCell style={{ minWidth: '50px' }} onClick={() => handleSort('price')} align="left">家賃{getSortDirection('price')}</TableCell>
                <TableCell style={{ minWidth: '80px' }} align="left">共益費</TableCell>
                <TableCell style={{ minWidth: '80px' }} onClick={() => handleSort('yearBuilt')} align="left">築年{getSortDirection('yearBuilt')}</TableCell>
                <TableCell style={{ minWidth: '80px' }} onClick={() => handleSort('exclusiveArea')} align="left">面積{getSortDirection('exclusiveArea')}</TableCell>
                <TableCell style={{ minWidth: '80px' }} align="left">間取り</TableCell>
                <TableCell style={{ minWidth: '150px' }} align="left">住所</TableCell>
                <TableCell style={{ minWidth: '150px' }} align="left">メモ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {estates.map((estate) => (
                <TableRow
                  key={estate.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {estate.id}
                  </TableCell>
                  <TableCell align="left">
                    {estate.url ? <a href = {estate.url} target = "_blank" rel="noopener noreferrer">{estate.name}</a> : estate.name}
                  </TableCell>
                  <TableCell align="left">{estate.price ? `¥${estate.price.toLocaleString()}` : ''}</TableCell>
                  <TableCell align="left">{estate.subPrice ? `¥${estate.price.toLocaleString()}` : ''}</TableCell>
                  <TableCell align="left">
                    {/* dateのデータの日付の表示は月まで */}
                    {estate.yearBuilt ? `${String(estate.yearBuilt).slice(0, 4)}/${String(estate.yearBuilt).slice(5, 7)}` : ''}
                  </TableCell>
                  <TableCell align="left">{estate.exclusiveArea}</TableCell>
                  <TableCell align="left">{estate.planOfAHouse}</TableCell>
                  <TableCell align="left">
                    {estate.map_url ? <a href = {estate.map_url} target = "_blank" rel="noopener noreferrer">{estate.address}</a> : estate.address}
                    </TableCell>
                  <TableCell align="left">{estate.memo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
export default Estates;