import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Top from './pages/Top';
import Estates from './pages/Estates';
import './App.css';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/estates" element={<Estates />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
