import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root') as HTMLElement; // HTMLElementとして明示的に型指定
const root = ReactDOM.createRoot(rootElement); // rootElementをcreateRootに渡す
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// パフォーマンス測定の関数を任意でパス可能
reportWebVitals();
